.container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2rem 3rem 5rem;
    background-color: var(--color-secondary);
  }
  
  .cardcontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .erroralert {
    margin: 6rem auto 3rem;
    text-align: center;
  }
  
  @media screen and (max-width: 600px) {
    .erroralert {
      font-size: 18px;
    }
  }
  
  @media screen and (max-width: 450px) {
    .container {
      padding: 2rem 1rem 5rem;
    }
  }
  