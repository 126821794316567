.container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 5rem;
    text-align: center;
  }
  
  .container .image {
    margin: 2.47em auto 1.47em;
  }

  .productimage{
    width: 766px;
    height: 496px;
    object-fit: cover;
  }
  
  .container h2 {
    font-size: 48px;
    margin: 2em auto 1.5em;
  }
  
  .container p {
    font-size: 24px;
  }
  
  @media screen and (max-width: 1200px) {
    .container {
      width: 100%;
    }
    
    .productimage{
      width: 90%;
      margin: auto;
      object-fit: cover;
    }
    
    .container h2 {
      font-size: 36px;
    }
  
    .container p {
      font-size: 18px;
    }
  }
  
  @media screen and (max-width: 600px) {
    .container {
      padding: 5rem 3rem;
      width: 100%;
    }

    .productimage{
      width: 300px;
      height: 250px;
      margin: auto;
      object-fit: cover;
    }
  
    .container h2 {
      font-size: 25px;
    }
  }
  