.card {
  background-color: var(--color-white);
  margin: 20px;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  height: 400px !important;
}

.subtitle {
  padding-left: 18px;
}

.textcontainer {
  display: flex;
  justify-content: space-between;
  padding: 14px 18px;
}

.textcontainer .title {
  font-size: 16px;
  font-weight: 700;
}

.textcontainer .date {
  opacity: 0.7;
}

.card p {
  line-height: 2rem;
  font-size: 15px;
  padding: 0px 18px;
}

.readmore {
  color: #4693e8;
  text-align: right;
  margin-right: 18px;
  margin-bottom: 18px;
}

.btn_container {
  display: flex;
  justify-content: flex-end;
}

.btn {
  display: block;
  padding: 0.4rem;
  margin: 0.5rem;
  border-radius: 10px;
  outline: none;
  border: none;
  font-size: var(--size-3);
  color: var(--color-white);
  cursor: pointer;
  background-color: var(--color-primary);
  text-align: center;
  font-weight: bold;
  width: 45%;
}

.readmore:hover {
  text-decoration: underline;
}

@media screen and (max-width: 1300px) {
  .card {
    margin: 10px;
  }
  .btn {
    font-size: 1rem;
  }
}

@media screen and (max-width: 600px) {
  .card {
    width: 100%;
    margin: 20px 40px;
  }

  .card p {
    font-size: 14px;
  }

  .btn {
    font-size: 0.8rem;
  }
}
