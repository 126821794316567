.btn {
    padding: 18px 30px;
    border-radius: 10px;
    outline: none;
    border: none;
    font-size: var(--size-1);
    color: var(--color-white);
    cursor: pointer;
    background-color: var(--color-primary);
    text-align: center;
    margin: 3rem auto;
    font-weight: bold;
  }
  
  .btn:hover {
    padding: 20px 32px;
    font-size: 20px;
    transition: all 0.2s ease-out;
  }
  
  @media screen and (max-width: 1200px) {
    .btn {
      font-size: 1rem;
    }
  }
  