.carousel_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* background-color: var(--color-secondary); */
  background-color: var(--color-secondary);
  align-items: center;
  justify-content: center;
}

.header {
  margin: 40px 0 40px 0;
  font-weight: bolder;
  font-size: 36px;
}

.carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 30%;
}

.carousel_width {
  width: 90%;
}

.imagecontainer {
  position: relative;
}

.container {
  width: 1400px;
  background-color: var(--color-secondary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3px;
  padding: 1rem;
  background-size: cover;
}

.imagetitle {
  position: absolute;
  bottom: 10px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0.8;
  color: white;
  text-align: center;
  padding: 0.5rem;
  display: none;
  text-align: center;
}

.imagecontainer img {
  max-width: 100%;
  max-height: 100%;
}

.imagetitle span {
  font-size: 12px;
}

.imagecontainer:hover .imagetitle {
  display: block;
}

@media screen and (max-width: 1100px) {
  .carousel_width {
    width: 100%;
  }
  .carousel {
    width: 80%;
  }

  .container {
    width: 100%;
  }
}
