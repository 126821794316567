.container {
  width: 100%;
  margin-top: 80px;
}

.container > div {
  position: unset !important;
}

.image {
  width: 100% !important;
  position: relative !important;
  height: unset !important;
  object-fit: cover;
}
