.container {
    width: 100%;
    display: flex;
    justify-content: stretch;
    align-items: center;
    margin-top: 80px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .textcontainer {
    background-color: var(--color-white);
    padding: 3rem 8rem 4rem;
    width: 100%;
    opacity: 0.75;
    display: flex;
    justify-content: center;
  }
  
  .textcontainer h1 {
    text-align: center;
    font-size: 48px;
  }
  
  @media screen and (max-width: 1200px) {
    .textcontainer h1 {
      font-size: 41px;
    }
  }
  
  @media screen and (max-width: 800px) {
    .textcontainer h1 {
      font-size: 33px;
    }
  }
  
  @media screen and (max-width: 600px) {
    .textcontainer h1 {
      font-size: 23px;
    }
  }
  