.container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2.5rem 3rem 5rem;
  }
  
  .container h1 {
    font-size: 36px;
    text-align: center;
    padding-bottom: 1rem;
  }
  
  .detailcard {
    background-color: var(--color-white);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 1000px;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  
  .detailcard .image {
    border-radius: 10px;
    flex: 1;
    margin-right: 8px;
  }
  
  .textcontainer {
    flex: 3;
    padding: 10px;
    font-size: 18px;
    line-height: 2rem;
    padding-bottom: 18px;
  }
  
  @media screen and (max-width: 1200px) {
    .container h1 {
      font-size: 29px;
    }
  }
  
  @media screen and (max-width: 1100px) {
    .detailcard {
      width: 80%;
      flex-direction: column;
      align-items: center;
    }
  
    .detailcard .imagecontainer {
      margin-right: 0px;
    }
  
    .textcontainer {
      order: 2;
    }
  }
  
  @media screen and (max-width: 600px) {
    .detailcard {
      width: 100%;
    }
    .textcontainer {
      font-size: 14px;
    }
  }
  