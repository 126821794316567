.navbar {
  background-color: var(--color-primary);
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: var(--size-1);
  position: fixed;
  top: 0;
  z-index: 1000;
}

.navbarlogo {
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
}

.navmenu {
  display: grid;
  grid-template-columns: repeat(7, auto);
  /* grid-template-columns: repeat(5, auto); */
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
  margin-right: 2rem;
}

.navitem {
  display: flex;
  align-items: center;
  height: 80px;
}

.navlinks {
  color: var(--color-white);
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.navlinks:hover {
  background-color: var(--color-background);
  color: var(--color-black);
  border-radius: 10px;
  transition: all 0.2s ease-out;
}

.menuicon {
  display: none;
}

.btn {
  padding: 8px 20px;
  border-radius: 10px;
  outline: none;
  border: none;
  font-size: var(--size-1);
  color: var(--color-black);
  cursor: pointer;
  background-color: var(--color-white);
  text-align: center;
}

.btn:hover {
  background-color: var(--color-background);
  transition: all 0.2s ease-out;
}

.dropdownmenu {
  position: absolute;
  top: 80px;
  list-style: none;
  text-align: start;
  color: var(--color-white);
  width: 130px;
  padding: 0;
  z-index: 1;
}

.dropdownmenu li {
  background-color: var(--color-primary);
  cursor: pointer;
}

.dropdownmenu li:hover {
  background-color: var(--color-background);
  color: var(--color-black);
}

.dropdownmenu .clicked {
  display: none;
}

.dropdownlink {
  text-decoration: none;
  padding: 1rem;
  display: block;
}

@media screen and (max-width: 1100px) {
  .navbar {
    position: absolute;
    font-size: small !important;
  }

  .navmenu {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* height: 530px; */
    height: 530px;
    position: absolute;
    top: 70px;
    left: -100%;
    transition: all 0.5s ease;
  }

  .navmenu.active {
    background: var(--color-secondary);
    left: 0px;
    transition: all 0.5s ease;
    z-index: 1;
    padding: 0;
  }

  .navlinks {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    color: var(--color-black);
  }

  .navlinks:hover {
    border: none;
    background-color: var(--color-primary);
    color: var(--color-white);
    border-radius: 0px;
  }

  .navbarlogo {
    position: absolute;
    top: 1;
    left: 0;
  }

  .menuicon {
    display: block;
    position: absolute;
    top: 10px;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: var(--color-white);
    width: 1.5rem;
    height: 1.5rem;
  }

  .dropdownmenu {
    top: 355px;
    width: 100%;
    color: var(--color-black);
    text-align: center;
  }

  .dropdownmenu li {
    background-color: var(--color-secondary);
  }

  .mobilebtn {
    display: block;
    text-align: center;
    padding: 1.3rem;
  }

  .btn:hover {
    color: var(--color-white);
    background-color: var(--color-primary);
  }
}
