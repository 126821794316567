.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 3rem;
  width: 80%;
  margin: auto;
}

.contactcontainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.contactcontainer .addresscontainer {
  flex: 1;
  /* margin-right: 1rem; */
  /* justify-content: space-between; */
}

.addresscontainer h4 {
  font-size: 30px;
  margin-bottom: 0px;
}

.addresscontainer p {
  font-size: 18px;
}

.contactcontainer .socialcontainer {
  flex: 1.5;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
}

.aboutcontainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.aboutcontainer div h4 {
  font-size: 23px;
  margin-bottom: 7px;
}

.aboutcontainer div p {
  font-size: 18px;
  margin: 7px auto;
}

.copyright {
  font-size: 18px;
  text-align: center;
}

.dropdown {
  width: 170px;
  user-select: none;
  position: relative;
}

.dropdownbtn {
  padding: 15px 20px;
  background-color: var(--color-primary);
  font-weight: bold;
  color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
}

.dropdowncontent {
  position: absolute;
  top: 110%;
  padding: 10px;
  background-color: var(--color-secondary);
  font-weight: 500;
  width: 100%;
  border-radius: 10px;
}

.dropdownitem {
  padding: 10px;
  cursor: pointer;
  transition: all 0.2s;
  color: var(--color-black);
}

.dropdownitem:hover {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.dropdownitem span {
  padding-left: 0.7rem;
}

@media screen and (max-width: 1600px) {
  .container {
    width: 60%;
  }
}

@media screen and (max-width: 1500px) {
  .container {
    width: 65%;
  }
}

@media screen and (max-width: 1400px) {
  .container {
    width: 70%;
  }
}

@media screen and (max-width: 1300px) {
  .container {
    width: 75%;
  }
}

@media screen and (max-width: 1200px) {
  .container {
    width: 80%;
  }
}

@media screen and (max-width: 1100px) {
  .container {
    width: 85%;
  }
}

@media screen and (max-width: 1000px) {
  .container {
    width: 90%;
  }
}

@media screen and (max-width: 900px) {
  .contactcontainer {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .contactcontainer .addresscontainer {
    margin-right: 0rem;
  }

  .contactcontainer .socialcontainer {
    width: 100%;
  }

  .aboutcontainer {
    flex-direction: column;
  }

  .dropdownbtn {
    order: 1;
    margin-top: 1.8rem;
  }

  .support {
    order: 2;
  }

  .team {
    order: 3;
  }

  .terms {
    order: 4;
  }
}

@media screen and (max-width: 600px) {
  .contactcontainer .socialcontainer {
    flex-direction: column;
  }

  .contactcontainer .socialcontainer a {
    margin: 0.8rem 0px;
  }
}

/* lang buttons */
.lang_button {
  padding: 10px 20px;
  background-color: #533125;
  color: white;
  margin-right: 10px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
}
